import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { B1White, Btn1Clear, colors, H1White } from "../../../styles/helpers"
import CommunityIcon from "../../Icons/CommunityIcon"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const Community = ({ data }) => {
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#community-trigger`,
          markers: false,
          start: "top 50%",
          toggleActions: "play none none none",
        },
      })
      .add("start")
      .fromTo(
        `.graphic-community`,
        {
          autoAlpha: 0,
          x: 300,
        },
        {
          autoAlpha: 1,
          ease: "elastic.out(1, 0.5)",
          x: 0,
          duration: 1.5,
        }
      )

      .fromTo(
        `.our-community__title`,
        {
          autoAlpha: 0,
          y: 300,
        },
        {
          autoAlpha: 1,
          ease: "back.out(1.7)",
          y: 0,
          duration: 1,
        },
        "start+=0.3"
      )

      .fromTo(
        `.our-community__content`,
        {
          autoAlpha: 0,
          y: 300,
        },
        {
          autoAlpha: 1,
          ease: "back.out(1.7)",
          y: 0,
          duration: 1,
        },
        "start+=0.6"
      )

      .fromTo(
        `.our-community__button`,
        {
          autoAlpha: 0,
          y: 300,
        },
        {
          autoAlpha: 1,
          ease: "back.out(1.7)",
          y: 0,
          duration: 1,
        },
        "start+=0.9"
      )
  }, [])

  return (
    <SectionStyled>
      <div id="community-trigger" className="wrapper">
        <div className="our-community">
          <div className="our-community__title">
            <h2>{data.template.templatePageHome.ourCommunityTitle}</h2>
          </div>
          <div
            className="our-community__content"
            dangerouslySetInnerHTML={{
              __html: data.template.templatePageHome.ourCommunityContent,
            }}
          />
          <div className="our-community__button">
            <Link
              to={`/${data.template.templatePageHome.ourCommunityButtonSlug}`}
            >
              {data.template.templatePageHome.ourCommunityButtonText}
            </Link>
          </div>
        </div>
        <div className="graphic-community">
          <div className="graphic-community__container">
            <CommunityIcon />
          </div>
        </div>
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  padding: 5rem 0;
  background-color: ${colors.colorPrimary};

  @media (min-width: 768px) {
    padding: 15rem 0;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .our-community {
    width: 100%;
    padding: 1rem 2rem;

    @media (min-width: 768px) {
      width: calc(60%);
      max-width: 65rem;
      margin-left: auto;
      padding: 0;
    }

    &__title {
      width: 100%;
      text-align: center;

      h2 {
        ${H1White};
      }
    }

    &__content {
      width: 100%;
      text-align: center;

      p {
        ${B1White};
      }
    }

    &__button {
      width: 100%;
      text-align: center;

      a {
        ${Btn1Clear};
      }
    }
  }

  .graphic-community {
    position: relative;
    width: 100%;
    z-index: 100;

    @media (min-width: 768px) {
      width: calc(40%);
    }

    &__container {
      margin-top: 5rem;
      padding: 0 2rem;

      @media (min-width: 768px) {
        position: absolute;
        top: -4rem;
        right: -30rem;
        left: 5rem;
        margin-top: 0;
        padding: 0;
        max-width: calc((2847 / 3) * 1px);
      }
    }
  }
`

export default Community
