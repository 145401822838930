import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Btn1Clear, H2White } from "../../../styles/helpers"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const BlockLink = ({ rowreverse, block, index }) => {
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#block-link-trigger-${index}`,
          markers: false,
          start: "top 50%",
          toggleActions: "play none none none",
        },
      })
      .fromTo(
        `#block-content-${index}`,
        {
          autoAlpha: 0,
          x: rowreverse ? -150 : 150,
        },
        {
          autoAlpha: 1,
          x: 0,
          duration: 0.75,
        }
      )
      .fromTo(
        `#block-image-${index}`,
        {
          autoAlpha: 0,
          y: 150,
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.75,
        }
      )
  }, [])

  const imageDisplay = getImage(
    block.image.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = block.image.altText
  return (
    <StyledDiv id={`block-link-trigger-${index}`} rowreverse={rowreverse}>
      <div id={`block-content-${index}`} className="block-content">
        <div className="block-content__title">
          <h2>{block.content}</h2>
        </div>
        <div className="block-content__links">
          <Link to={`/${block.buttonSlug}`}>{block.buttonText}</Link>
        </div>
      </div>
      <div id={`block-image-${index}`} className="block-image">
        <GatsbyImage
          image={imageDisplay}
          alt={imageAlt}
          layout="fullWidth"
          formats={["auto", "webp", "avif"]}
        />
      </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-bottom: 2.5rem;
  overflow: hidden;

  @media (min-width: 768px) {
    flex-direction: ${props => (props.rowreverse ? "row" : "row-reverse")};
  }

  .block-content {
    width: 100%;
    padding: 5rem 2rem;
    background-color: rgba(0, 41, 106, 1);

    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: ${props => (props.rowreverse ? "auto" : "0")};
      bottom: 0;
      left: ${props => (props.rowreverse ? "0" : "auto")};
      width: calc(45%);
      padding: 2rem;
      background-color: rgba(0, 41, 106, 0.9);
      text-align: center;
      z-index: 500;
    }

    @media (min-width: 1025px) {
      padding: 5rem;
    }

    &__title {
      width: 100%;
      text-align: center;

      h2 {
        ${H2White};
        margin: 0;
      }
    }

    &__links {
      width: 100%;
      margin-top: 3rem;
      text-align: center;

      @media (min-width: 768px) {
        margin-top: 0;
      }

      a {
        ${Btn1Clear};
      }
    }
  }

  .block-image {
    width: 100%;
  }
`

export default BlockLink
