import React from "react"
import styled from "styled-components"
import { medWrapper } from "../../../styles/helpers"
import BackgroundOne from "../../Icons/BackgroundOne"
import BlockLink from "./BlockLink"

const BlocksLinks = ({ data }) => {
  const blocks = data.template.templatePageHome.blockLinks

  return (
    <SectionStyled>
      <div className="wrapper">
        {blocks.map((block, index) => (
          <BlockLink
            key={index}
            index={index}
            rowreverse={index % 2 === 0}
            block={block}
          />
        ))}
      </div>
      <div className="bg-one">
        <BackgroundOne />
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  position: relative;
  padding: 2.5rem 0;

  @media (min-width: 768px) {
    padding: 6rem 0;
  }

  @media (min-width: 1025px) {
    padding: 12rem 0;
  }
  .wrapper {
    ${medWrapper};
  }

  .bg-one {
    position: absolute;
    bottom: -5rem;
    left: -10rem;
    max-width: 40rem;
    z-index: -1;
  }
`

export default BlocksLinks
