import React, { useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { B2LightBlue, H1Blue } from "../../../styles/helpers"

import Fire from "../../Fire"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const AwesomeContent = ({ data }) => {
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#awesome-trigger`,
          markers: false,
          start: "top 40%",
          toggleActions: "play none none none",
        },
      })
      .add("start")
      .fromTo(
        `.awesome-content__title`,
        {
          autoAlpha: 0,
          y: 300,
        },
        {
          autoAlpha: 1,
          ease: "back.out(1.7)",
          y: 0,
          duration: 1.5,
        }
      )

      .fromTo(
        `.awesome-content__content`,
        {
          autoAlpha: 0,
          y: 300,
        },
        {
          autoAlpha: 1,
          ease: "back.out(1.7)",
          y: 0,
          duration: 1,
        },
        "start+=0.3"
      )

      .fromTo(
        `.awesome-content__icon`,
        {
          autoAlpha: 0,
          x: 300,
        },
        {
          autoAlpha: 1,
          ease: "elastic.out(1, 0.5)",
          x: 0,
          duration: 1.5,
        },
        "start+=0.75"
      )
  }, [])

  const imageDisplay = getImage(
    data.template.templatePageHome.contentAwesomeImage.localFile.childImageSharp
      .gatsbyImageData
  )
  const imageAlt = data.template.templatePageHome.contentAwesomeImage.altText

  const iconDisplay = getImage(
    data.template.templatePageHome.contentAwesomeIcon.localFile.childImageSharp
      .gatsbyImageData
  )
  const iconAlt = data.template.templatePageHome.contentAwesomeIcon.altText

  return (
    <SectionStyled>
      <div id="awesome-trigger" className="wrapper">
        <div className="awesome-image">
          <GatsbyImage
            image={imageDisplay}
            alt={imageAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
        <div className="awesome-content">
          <div className="awesome-content__icon">
            <GatsbyImage
              image={iconDisplay}
              alt={iconAlt}
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </div>

          <div className="awesome-content__title">
            <h2>{data.template.templatePageHome.contentAwesomeTitle}</h2>
          </div>
          <div
            className="awesome-content__content"
            dangerouslySetInnerHTML={{
              __html: data.template.templatePageHome.contentAwesomeContent,
            }}
          />
        </div>
      </div>
      <Fire />
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  position: relative;
  margin-top: 12.5rem;

  .wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  .awesome-image {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(30vw);
    }
  }

  .awesome-content {
    width: 100%;
    padding: 2.5rem;

    @media (min-width: 768px) {
      width: calc(70vw);
      max-width: 65rem;
      margin-right: auto;
      padding-left: 10rem;
    }

    @media (min-width: 1025px) {
      max-width: 65rem;
    }

    &__icon {
      width: 100%;
      max-width: 25rem;

      @media (min-width: 768px) {
        max-width: 30rem;
      }

      @media (min-width: 1025px) {
        max-width: 40rem;
      }
    }

    &__title {
      width: 100%;
      h2 {
        ${H1Blue};
      }
    }

    &__content {
      width: 100%;

      p {
        ${B2LightBlue};

        &:last-of-type {
          margin: 0;
        }
      }
    }
  }
`

export default AwesomeContent
