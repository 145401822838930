import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import HeroButtons from "../components/templates/common/HeroButtons"
import AwesomeContent from "../components/templates/home/AwesomeContent"
import DrMo from "../components/templates/common/DrMo"
import Community from "../components/templates/home/Community"
import BlocksLinks from "../components/templates/home/BlocksLinks"
import Location from "../components/templates/common/Location"
import SocialMedia from "../components/templates/home/SocialMedia"

const IndexPage = props => {
  const {
    hero,
    awesomeContent,
    drMo,
    community,
    blocksLinks,
    location,
    social,
    seoInfo,
  } = props.data

  const heroData = hero.template.templatePageHome
  const drMoData = drMo.template.templatePageHome
  const locationData = location.template.templatePageHome

  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <HeroButtons data={heroData} />
      <AwesomeContent data={awesomeContent} />
      <DrMo data={drMoData} />
      <Community data={community} />
      <BlocksLinks data={blocksLinks} />
      <Location data={locationData} />
      <SocialMedia data={social} />
    </Layout>
  )
}

export const homeQuery = graphql`
  {
    seoInfo: wpPage(slug: { eq: "home" }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }
    hero: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          templatePageHome {
            buttonOneType
            buttonOneText
            buttonOneSlug
            buttonOneUrl
            buttonOnePhone
            buttonTwoType
            buttonTwoText
            buttonTwoSlug
            buttonTwoUrl
            buttonTwoPhone
            heroImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }

            heroLogo {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }

    awesomeContent: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          templatePageHome {
            contentAwesomeContent
            contentAwesomeTitle
            contentAwesomeIcon {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
            contentAwesomeImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
          }
        }
      }
    }

    drMo: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          templatePageHome {
            drMoContent
            drMoTitle
            drMoReverse
            drMoIcon {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }

    community: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          templatePageHome {
            ourCommunityTitle
            ourCommunityContent
            ourCommunityButtonText
            ourCommunityButtonSlug
          }
        }
      }
    }

    blocksLinks: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          templatePageHome {
            blockLinks {
              content
              buttonText
              buttonSlug
              image {
                altText
                sourceUrl
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData(width: 1500)
                  }
                }
              }
            }
          }
        }
      }
    }

    location: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          templatePageHome {
            displayLocation
          }
        }
      }
    }

    social: wpPage(slug: { eq: "home" }) {
      template {
        ... on WpTemplate_Home {
          templatePageHome {
            displaySocialMedia
          }
        }
      }
    }
  }
`

export default IndexPage
