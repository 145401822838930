import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { colors, H1Blue, standardWrapper } from "../../../styles/helpers"

import Twitter from "../../Icons/Twitter"
import Facebook from "../../Icons/Facebook"
import Instagram from "../../Icons/Instagram"
import BackgroundOne from "../../Icons/BackgroundOne"

const getData = graphql`
  {
    socialMedia: wp {
      acfOptionsSiteWideSettings {
        acfSiteWideSettings {
          instagramUrl
          twitterUrl
          facebookUrl
        }
      }
    }
  }
`

const SocialMedia = () => {
  const socialMediaData = useStaticQuery(getData)

  const { instagramUrl, facebookUrl, twitterUrl } =
    socialMediaData.socialMedia.acfOptionsSiteWideSettings.acfSiteWideSettings

  return (
    <StyledDiv>
      <div className="wrapper">
        <p>Follow Us</p>
        <ul>
          <StyledIcon>
            <a
              title="Follow us on Facebook - Link will open in new window"
              target="_blank"
              rel="noreferrer"
              href={facebookUrl}
            >
              <i>
                <Facebook />
                <span className="visuallyhidden">Facebook</span>
              </i>
            </a>
          </StyledIcon>

          <StyledIcon>
            <a
              title="Follow us on Instagram - Link will open in new window"
              target="_blank"
              rel="noreferrer"
              href={instagramUrl}
            >
              <i>
                <Instagram />
                <span className="visuallyhidden">Instagram</span>
              </i>
            </a>
          </StyledIcon>

          <StyledIcon>
            <a
              target="_blank"
              rel="noreferrer"
              title="Follow us on Twitter - Link will open in new window"
              href={twitterUrl}
            >
              <i>
                <Twitter />
                <span className="visuallyhidden">Twitter</span>
              </i>
            </a>
          </StyledIcon>
        </ul>
      </div>
      <div className="bg-one">
        <BackgroundOne />
      </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  position: relative;
  padding: 2.5rem 0;
  overflow: hidden;

  @media (min-width: 768px) {
    padding: 5rem 0;
  }

  @media (min-width: 1025px) {
    padding: 10rem 0;
  }

  .wrapper {
    ${standardWrapper};
    align-items: center;
  }

  p {
    ${H1Blue};
    width: 100%;
    text-align: center;

    @media (min-width: 768px) {
      width: auto;
      margin: 0;
      margin-right: 2rem;
    }
  }

  .bg-one {
    display: none;
    position: absolute;
    bottom: -30rem;
    right: -14rem;
    max-width: 40rem;
    z-index: -1;

    @media (min-width: 768px) {
      display: block;
      bottom: -25rem;
    }
  }
`

const StyledIcon = styled.li`
  display: inline-block;
  margin-right: 1.5rem;
  margin-left: 1.5rem;

  a {
    display: block;
    position: relative;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    @media (min-width: 768px) {
      width: 4rem;
      height: 4rem;
    }
    @media (min-width: 1025px) {
      width: 5rem;
      height: 5rem;
    }

    &:focus {
      outline: 0.4rem solid #003b49;
      transition: outline-width 0.35s ease-in-out;
    }

    .visuallyhidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    svg {
      display: block;
      width: 4rem;
      height: 4rem;
      margin: auto;
      transition: all 0.3s ease-out;
      fill: ${colors.colorPrimary};

      @media (min-width: 768px) {
        width: 4rem;
        height: 4rem;
      }
      @media (min-width: 1025px) {
        width: 5rem;
        height: 5rem;
      }

      &:hover {
        fill: ${colors.colorTertiary};
      }
    }
  }
`

export default SocialMedia
